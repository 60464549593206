<template>
	<div>
        <div class="col-12 row">
            <form id="filters" class="row align-items-center pb-1">
                <div class="filter col-8">
                    <div>
                        <span class="p-input-icon-left search w-100 bg-inputs">
                            <input v-model="search" class="p-inputtext p-component br-25 col-3 w-100" name="buscar" placeholder="Buscar...">
                        </span>
                    </div>
                </div>
                <div class="action-buttons d-flex gap-3 align-items-center justify-content-md-end pb-1 f-right col-4 add">
                    <button @click="addRegister()" class="p-button p-component btn-primary text-white mr-custom-12" type="button" aria-label="Añadir juego">
                        <img src="@/assets/images/icons/add_object.svg" width="24" style="margin-right: 10px;"> 
                        Añadir objeto
                        <span class="p-ink" role="presentation" style="height: 184px; width: 184px; top: -66.7969px; left: -73.1562px;"></span>
                    </button>
                </div>
            </form>
            <div class="col-12 my-5 d-contents">
                <div class="col-6 p-c-8" v-for="(object, index) in face_to_face_objects.list" :key="index">
                    <div class="custom-card">
                        <div>
                            <img width="40" src="@/assets/images/icons/objects_tarjet.svg" />
                            <label class="ml-c-8 my-auto">{{ object.name }}</label>
                        </div>
                        <div>
							<img style="margin-right: 16px;" class="cursor-pointer" width="24" src="@/assets/images/icons/language.svg" @click="showRegister(object, 'language')" />
                            <img class="pr-c-16 cursor-pointer" src="@/assets/images/icons/edit.svg" @click="showRegister(object, 'edit')"/>
                            <img class="cursor-pointer" src="@/assets/images/icons/block.svg" @click="deleteRegister(object.id)" />
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="array_objects != null" class="p-paginator-bottom mt-c-10">
                <div class="p-paginator p-component" pv_id_6="">
                    <button class="p-paginator-first p-paginator-element p-link p-disabled" type="button" disabled="">
                        <span class="p-paginator-icon pi pi-angle-double-left"></span>
                        <span class="p-ink" role="presentation"></span>
                    </button>
                    <button class="p-paginator-prev p-paginator-element p-link p-disabled" type="button" disabled="">
                        <span class="p-paginator-icon pi pi-angle-left"></span><span class="p-ink" role="presentation"></span>
                    </button>
                    <span class="p-paginator-pages"></span>
                    <button class="p-paginator-next p-paginator-element p-link p-disabled" type="button" disabled="">
                        <span class="p-paginator-icon pi pi-angle-right"></span>
                        <span class="p-ink" role="presentation"></span>
                    </button>
                    <button class="p-paginator-last p-paginator-element p-link p-disabled" type="button" disabled="">
                        <span class="p-paginator-icon pi pi-angle-double-right"></span>
                        <span class="p-ink" role="presentation"></span>
                    </button>
                    <span class="p-paginator-current">Mostrando del 0 al 0 de un total de 0 registros</span>
                    <div id="pv_id_4" class="p-dropdown p-component p-inputwrapper p-disabled p-inputwrapper-filled p-paginator-rpp-options">
                        <span class="p-dropdown-label p-inputtext" tabindex="-1" role="combobox" aria-label="5" aria-haspopup="listbox" aria-expanded="false" aria-controls="pv_id_4_list" aria-disabled="true">5</span>
                        <div class="p-dropdown-trigger">
                            <span class="p-dropdown-trigger-icon pi pi-chevron-down" aria-hidden="true"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

	export default {
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data() {
			return {
				filters: [
					{
						name: "buscar",
						value: null,
						type: "string",
						placeholder: "Buscar...",
					},
					{
						name: "from",
						value: null,
						type: "date",
						placeholder: "Desde",
					},
					{
						name: "to",
						value: null,
						type: "date",
						placeholder: "Hasta",
					},
                    {
						name: "button_filter",
						value: 0
					}
				],
                search: null
			};
		},
		methods: {
			...mapActions(["deleteRegisters", "getObjects"]),
			...mapMutations([
				"toggleFormDialog",
				"changeFormDialogMode",
				"changeCurrentRegister",
				"toggleInfoDialog",
                "toggleInfoFaceToFaceDialog",
				"toggleFormLanguageDialog"
			]),
            addRegister() {
				this.toggleFormDialog({
					stateVariable: this.stateVariable,
					show: true,
				});

				this.$emit("addRegister");
			},
            showRegister(e, type, info = false) {
				if (!info) {
					if(type == 'language'){
						const register = e.data != undefined ? e.data : e;

						this.changeCurrentRegister({
							stateVariable: this.stateVariable,
							register,
						});
						this.toggleFormLanguageDialog({
							stateVariable: this.stateVariable,
							show: true,
						});

						this.$emit("showRegister", register, type);

					}else{
						const register = e.data != undefined ? e.data : e;

						this.changeCurrentRegister({
							stateVariable: this.stateVariable,
							register,
						});

						this.changeFormDialogMode({
							stateVariable: this.stateVariable,
							dialogMode: type,
						});

						if(this.stateVariable == 'face_to_face_games'){
							this.toggleInfoFaceToFaceDialog({
								stateVariable: this.stateVariable,
								show: true,
							});
							
						}else{
							this.toggleFormDialog({
								stateVariable: this.stateVariable,
								show: true,
							});
						}

						this.$emit("showRegister", register, type);
					}

				} else {
					const register = e.data != undefined ? e.data : e;

					this.changeCurrentRegister({
						stateVariable: this.stateVariable,
						register,
					});

					this.toggleInfoDialog(true);
				}
			},
            deleteRegister(id) {
				this.$confirm.require({
					message: "¿Desea borrar el registro?",
					header: "Borrar registro",
					icon: "pi pi-exclamation-triangle",
					accept: () => {
						this.deleteRegisters({
							url: "/delete-object/" + id
						}).then(() => {
							this.getObjects();
						});
					},
				});
			},
		},
		computed: {
			...mapState(["face_to_face_objects"]),
		},
        mounted() {
            this.getObjects();
        },
        watch: {
			search: {
				handler(value) {
                    var parameters = {
                        "search": value
                    }
                    this.getObjects(parameters);
                }
            }
        }
	};
</script>

<style lang="scss" scoped>
	.p-avatar.not-active, .rol.not-active {
		background-color: #ABAFB3 !important;
		color: white !important;
		border-width: 0px;
	}

	.f-12{
		font-size: 12px;
	}

	.f-14{
		font-size: 14px;
	}

	.br-8{
		border-radius: 8px !important;
	}

	.gray-color{
		color: #B5B5C3; 
	}

	.fw-500{
		font-weight: 500;
	}

	.border-1{
		border-width: 1px;
		border-style: solid;
	}

	.not-active {
		filter: grayscale(100%);
	}

    .d-contents{
        display: contents;
    }

    .ml-c-8{
        margin-left: 8px;
    }

    .custom-card{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1 0 0;
        padding: 16px;
        border-radius: 8px;
        background: #FFF;
        box-shadow: 0px 8px 24px 0px rgba(33, 139, 244, 0.10);
    }

    .p-c-8{
        padding: 8px;
    }

    .pr-c-16{
        padding-right: 16px;
    }

    .mt-c-10{
        margin-top: 10px;
    }

    span.search:before {
		content: "";
		position: absolute;
		right: 35px;
		top: 0;
		bottom: 0;
		width: 22px;
		background: url("@/assets/images/icons/search_icon.svg") center / contain no-repeat;
	}

    span.search{
		input{
			padding-left: 15px !important;
		}
	}
</style>
