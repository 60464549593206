<template>
	<Dialog
		v-model:visible="$store.state.languages.dialog"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '25vw' }"
		:modal="dialogDefaults.modal"
		:closeOnEscape="dialogDefaults.closeOnEscape"
		:dismissableMask="dialogDefaults.dismissableMask"
		@hide="hide"
		@show="show"
	>
		<template #header>
			<FormTitle :title="title" />
		</template>

		<form @keydown="$event.key === 'Enter' ? save() : null">
			<div class="row gy-3 pt-1">
				<div class="col-12">
					<InputText
						ref="name"
						label="Nombre del idioma *"
						:disabled="disabled"
						:error="form.errors.get('name')"
						class="bg-inputs"
						:placeholder="'Introduce el nombre'"
						@change-value="(value) => (form.name = value)"
					/>
				</div>
                <div class="col-12">
					<InputText
						ref="code"
						label="Código del idioma *"
						:disabled="disabled"
						:error="form.errors.get('code')"
						class="bg-inputs"
						:placeholder="'Introduce el código'"
						@change-value="(value) => (form.code = value)"
					/>
				</div>
			</div>
		</form>

		<template #footer v-if="!disabled">
			<div class="col-12 d-flex justify-content-between">
				<Button
					@click="
						toggleFormDialog({
							stateVariable: stateVariable,
							value: false,
						})
					"
					label="Cancelar"
					:disabled="form.busy"
					class="bg-white border btn-secundary"
				/>
				<Button @click="save()" class="btn-primary" :label="label_button" :disabled="form.busy" />
			</div>
		</template>
	</Dialog>
</template>

<script>
	import Form from "vform";
	import { mapActions, mapMutations, mapState } from "vuex";

	export default {
		components: {
		},
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data: () => ({
			form: new Form(),
			modelName: "idioma",
			title: `Añadir idioma`,
			disabled: false,
			lastPageChange: null,
			language_id: 0,
			label_button: "Añadir",
		}),
		methods: {
			...mapActions(["sendForm", "getRegisters"]),
			...mapMutations(["toggleFormDialog", "changeCurrentRegister"]),
			save() {
				const update = this.languages.register != null;
				var url = "/create-language";
				if(update){
					url = "/update-language";
					this.form.language_id = this.languages.register.id;
				}

				this.sendForm({
					method: update ? "put" : "post",
					form: this.form,
					url: url,
					errors: this.form.errors,
				}).then((response) => {
					if (response.status === 200 && response.data.code == 1000) {
						this.toggleFormDialog({
							stateVariable: this.stateVariable,
							value: false,
						});

						this.getRegisters({
							route: 'get-languages',
							stateVariable: this.stateVariable,
							page: this.languages.currentPage,
							rows: this.languages.rows,
						});
						this.clearForm();
					}
				});
			},
			hide() {
				this.title = `Añadir ${this.modelName}`;
				this.label_button = 'Añadir';
				this.disabled = false;
				this.checked = 1;
				this.languages.dialogMode = 'add';

				this.changeCurrentRegister({
					stateVariable: this.stateVariable,
					register: null,
				});
			},
			clearForm() {
				this.form.clear();
				this.form.reset();

				for (const key in this.$refs) {
					if (Object.hasOwnProperty.call(this.$refs, key)) {
						this.$refs[key].model = null;
					}
				}
			},
			show() {
				this.clearForm();

				const register = this.languages.register;

				if (register != null) {
					for (const key in register) {
						if (
							Object.hasOwnProperty.call(register, key) &&
							this.$refs[key] != undefined
						) {
							this.$refs[key].model = register[key];
						}
					}
					this.checked = register.role_id;

					if (this.languages.dialogMode == "edit") {
						this.title = `Modificar ${this.modelName}`;
						this.label_button = 'Guardar';
						this.disabled = false;
						this.object_id = register.id;

					} else {
						this.title = `Ver ${this.modelName}`;
						this.disabled = true;
					}
				}
			},
		},
		computed: {
			...mapState(["dialogDefaults", "languages"]),
		},
		mounted(){
			
		}
	};
</script>
<style lang="scss" scoped>
	.ml-2{
		margin-left: 0.5rem;
	}

	.mr-4{
		margin-right: 1rem;
	}

	.ml-4{
		margin-left: 1rem;
	}

	.pyc-17{
		padding-top: 17px;
		padding-bottom: 17px;
	}

	.br-29{
		border-radius: 29px;
	}

	.br-29.selected{
		border-width: 1px;
		border-color: $primary;
		border-style: solid;
		color: #464E5F !important;
	}

	.br-50{
		border-radius: 50%;
	}
</style>