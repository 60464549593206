<template>
	<div>
		<div class="d-none d-md-flex align-items-center text-primary-dark h4 mb-0 px-2 pb-4">
			<b>{{ $route.name }}</b>
		</div>
		<TabView>
			<TabPanel header="Juegos">
				<FaceToFaceGamesTable :route="route" :stateVariable="stateVariable" />
			</TabPanel>
			<TabPanel header="Objetos">
				<ObjectsTable :route="'face_to_face_objects'" :stateVariable="'face_to_face_objects'"/>
			</TabPanel>
			<TabPanel header="Idiomas">
				<LanguageTable :route="'languages'" :stateVariable="'languages'"/>
			</TabPanel>
		</TabView>
        
		<GameForm :route="route" :stateVariable="stateVariable"/>
		<GameLanguageForm :route="route" :stateVariable="stateVariable"/>
		<GameDescription :route="route" :stateVariable="stateVariable"/>
		<ObjectForm :route="'face_to_face_objects'" :stateVariable="'face_to_face_objects'"/>
		<ObjectLanguageForm :route="'face_to_face_objects'" :stateVariable="'face_to_face_objects'"/>
		<LanguageForm :route="'languages'" :stateVariable="'languages'"/>
		
	</div>
</template>

<script>
	import FaceToFaceGamesTable from "./FaceToFaceGamesTable.vue";
	import ObjectsTable from "./ObjectsTable.vue";
	import TabView from 'primevue/tabview';
	import TabPanel from 'primevue/tabpanel';
	import GameForm from "./GameForm.vue";
	import GameLanguageForm  from './/GameLanguageForm.vue';
	import GameDescription from "./GameDescription.vue";
	import ObjectForm from "./ObjectForm.vue";
	import ObjectLanguageForm from "./ObjectLanguageForm.vue";
	import LanguageTable from "./LanguageTable.vue";
	import LanguageForm from "./LanguageForm.vue";

	export default {
		components: {
            FaceToFaceGamesTable,
			ObjectsTable,
			TabView,
			TabPanel,
			GameForm,
			GameLanguageForm,
			GameDescription,
			ObjectForm,
			LanguageTable,
			LanguageForm,
			ObjectLanguageForm
		},
		data() {
			return {
				route: "face_to_face_games",
				stateVariable: "face_to_face_games",
			};
		},
	};
</script>
<style lang="scss" scoped>
	
</style>