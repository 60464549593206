<template>
	<TableDefault
		ref="table"
		:route="route"
		:stateVariable="stateVariable"
		:list="users.list"
		:total="users.listTotal"
		:filters="filters"
		:type_filter="1"
		:delete="'users'"
		:showActionsColumn="false"
		:showDeleteButton="false"
		:showTitle="false"
		:addButtonText="'Añadir usuario'"
		:showSelectMultipleColumn="false"
	>
		<template #columns>
			<Column header="NOMBRE">
				<template #body="slotProps">
					<div class="d-flex align-items-center gap-2">
						<Avatar
							:class="{ 'not-active': !slotProps.data.active }"
							class="bg-light-primary text-primary border-radius f-12"
							:label="firstTwoLetters(slotProps.data.name)"
						/>

						<b :class="{ 'gray-color': !slotProps.data.active }">{{ slotProps.data.name }}</b>
					</div>
				</template>
			</Column>
			<Column header="EMAIL">
				<template #body="slotProps">
					<span class="fw-500" :class="{ 'gray-color': !slotProps.data.active }">
						{{ slotProps.data.email }}
					</span>
				</template>
			</Column>
			<Column header="ROL">
				<template #body="slotProps">
					<div class="d-flex">
						<div
							v-if="slotProps.data.role_id == 1"
							:class="{ 'not-active': !slotProps.data.active }"
							class="bg-primary px-3 py-1 text-white br-8 f-12 rol"
						>
							{{ slotProps.data.role.slug }}
						</div>
						<div
							v-else-if="slotProps.data.role_id == 2"
							:class="{ 'not-active': !slotProps.data.active }"
							class="bg-light-primary border-primary text-primary px-3 py-1 br-8 f-12 rol border-1" 
						>
							{{ slotProps.data.role.slug }}
						</div>
						<div
							v-else
							:class="{ 'not-active': !slotProps.data.active }"
							class="bg-light-primary text-white px-3 py-1 br-8 f-12 rol" 
						>
							{{ slotProps.data.role.slug }}
						</div>
					</div>
				</template>
			</Column>
			<Column header="FECHA">
				<template #body="slotProps">
					<span class="gray-color">
						{{ $helper.formatDate(slotProps.data.created_at, "DD/MM/YYYY") }}
					</span>
				</template>
			</Column>
			<Column header="">
				<template #body="slotProps">
					<Button class="table-custom-button-action d-flex gap-3">
						<img
							v-if="$helper.checkUserHasPermission(route, 'update')"
							src="@/assets/images/icons/edit.svg"
							@click="showRegister(slotProps.data, 'edit')"
						/>

						<img
							v-if="$helper.checkUserHasPermission(route, 'delete')"
							src="@/assets/images/icons/block.svg"
							@click="deleteRegister(slotProps.data.id, slotProps.data.active)"
							:class="[slotProps.data.active ? '' : 'not-active']"
						/>
					</Button>
				</template>
			</Column>
		</template>
	</TableDefault>
</template>

<script>
	import Avatar from "primevue/avatar";
	import Column from "primevue/column";
	import { mapState, mapActions, mapMutations } from "vuex";

	export default {
		components: {
			Column,
			Avatar,
		},
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data() {
			return {
				filters: [
					{
						name: "buscar",
						value: null,
						type: "string",
						placeholder: "Buscar...",
					},
					{
						name: "email",
						value: null,
						type: "string",
						placeholder: "Filtrar por email",
					},
					{
						name: "role",
						value: null,
						type: "dropdown",
						placeholder: "Filtrar por Rol",
						options: [
							{
								label: "Administrador",
								value: "super_admin",
							},
							{
								label: "Cliente",
								value: "customer",
							},
						],
					},
					{
						name: "from",
						value: null,
						type: "date",
						placeholder: "Desde",
					},
					{
						name: "to",
						value: null,
						type: "date",
						placeholder: "Hasta",
					},
					{
						name: "button_filter",
						value: 0
					}
				],
			};
		},
		methods: {
			...mapActions(["deleteRegisters", "getList"]),
			...mapMutations([
				"toggleFormDialog",
				"changeFormDialogMode",
				"changeCurrentRegister",
				"toggleInfoDialog",
			]),
			firstTwoLetters(name) {
				return name.substring(0, 2);
			},
			showRegister(e, type, info = false) {
				if (!info) {
					this.$refs.table.showRegister(e, type);
				} else {
					const register = e.data != undefined ? e.data : e;

					this.changeCurrentRegister({
						stateVariable: this.stateVariable,
						register,
					});

					this.toggleInfoDialog(true);
				}
			},
			deleteRegister(id, active) {
				const lastPageChange = this.$refs.table.lastPageChange;

				this.$confirm.require({
					message: active
						? "¿Desea bloquear el usuario?"
						: "¿Desea desbloquear el usuario?",
					header: "Cambiar estado",
					icon: "pi pi-exclamation-triangle",
					accept: () => {
						this.deleteRegisters({
							url: `/users/${id}`,
						}).then(() => {
							this.$refs.table.getList(lastPageChange);
						});
					},
				});
			},
		},
		computed: {
			...mapState(["users"]),
		},
	};
</script>

<style lang="scss" scoped>
	.p-avatar.not-active, .rol.not-active {
		background-color: #ABAFB3 !important;
		color: white !important;
		border-width: 0px;
	}

	.gray-color{
		color: #B5B5C3; 
	}

	.fw-500{
		font-weight: 500;
	}

	.border-1{
		border-width: 1px;
		border-style: solid;
	}

	.not-active {
		filter: grayscale(100%);
	}
</style>
