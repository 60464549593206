<template>
	<router-link
		:to="item.href"
		class="link d-flex align-items-center p-3 gap-2 p-ripple"
		v-ripple
	>
		<div class="logo">
			<img :src="image" :width="item.width" />
		</div>
		<p class="custom-line-heigth mb-0 ml-2">
			{{ item.label }}
		</p>
	</router-link>
</template>

<script>
	export default {
		props: ["item"],
		computed: {
			image() {
				const img = this.item.active
					? this.item.permission + "-selected"
					: this.item.permission;
				return require(`@/assets/images/sidebar/${img}.svg`);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.link {
		border-radius: 0px !important;
		text-decoration: none;
		transition: $transition;
		color: #218BF4;
		border-radius: $border-radius;

		.custom-line-heigth {
			line-height: 24px;
		}

		i {
			line-height: 24px;
			transform: translateY(-1px);
		}

		&.router-link-active {
			background-color: #218BF4;
			color: white;
			font-weight: bold;
		}

		&:hover {
			background-color: rgba(#218BF4, 0.7);
			color: white;
		}

		position: relative;
	}
	.link:before {
		content: "";
		position: absolute;
		right: 0px;
		bottom: 0;
		width: 0;
		height: 0;
		border-right: 28px solid white;
		border-top: 28px solid transparent;
		border-bottom: 28px solid transparent;
	}

	.ml-2{
		margin-left: 1rem;;
	}

	.logo{
		width: 24px;
		height: 24px;
		text-align: center;
	}
</style>
