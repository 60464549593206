<template>
	<TableDefault
		ref="table"
		:route="'get-games'"
		:stateVariable="stateVariable"
		:list="face_to_face_games.list"
		:total="face_to_face_games.listTotal"
		:filters="filters"
		:type_filter="0"
		:delete="'game_tables'"
		:showActionsColumn="false"
		:showDeleteButton="false"
		:showTitle="false"
		:addButtonText="'Añadir juego'"
		:showSelectMultipleColumn="false"
	>
		<template #columns>
			<Column field="name">
                <template #header>
                    <span style="width: 300px !important;">NOMBRE DEL JUEGO</span>
                </template>
                <template #body="slotProps">
                    <span style="width: 300px !important;">{{ slotProps.data.name }}</span>
                </template>
            </Column>
			<Column field="guide">
                <template #header>
                    <span style="width: 400px !important;">GUÍA</span>
                </template>
                <template #body="slotProps">
                    <span v-html="slotProps.data.guide_text" />
                </template>
            </Column>
			<Column field="created_at">
                <template #header>
                    <span style="width: 150px !important;">FECHA</span>
                </template>
                <template #body="slotProps">
                    <span style="width: 150px !important;">{{ $helper.formatDate(slotProps.data.created_at, "DD/MM/YYYY") }}</span>
                </template>
            </Column>
			<Column field="publish">
                <template #header>
                    <span style="width: 150px !important;">ESTADO</span>
                </template>
                <template #body="slotProps">
                    <span v-if="slotProps.data.publish == 1" style="width: 150px !important;">PUBLICADO</span>
					<span v-else style="width: 150px !important;">NO PUBLICADO</span>
                </template>
            </Column>
			<Column>
				<template #header>
                    <span style="width: 300px !important;"></span>
                </template>
				<template #body="slotProps">
					<Button class="table-custom-button-action d-flex gap-3" style="width: 300px !important;">
						<img
							width="24"
							v-if="$helper.checkUserHasPermission(route, 'delete')"
							src="@/assets/images/icons/language.svg"
							@click="showRegister(slotProps.data, 'language')"
						/>
						
						<img
							v-if="$helper.checkUserHasPermission(route, 'delete')"
							src="@/assets/images/icons/eye_green.svg"
							@click="showRegister(slotProps.data, 'show')"
						/>

						<img
							v-if="$helper.checkUserHasPermission(route, 'update')"
							src="@/assets/images/icons/edit.svg"
							@click="showRegister(slotProps.data, 'edit')"
						/>

						<img
							v-if="$helper.checkUserHasPermission(route, 'publish')"
							src="@/assets/images/icons/upload.svg"
							@click="publishGameAction(slotProps.data.id, slotProps.data.active)"
						/>

						<img
							v-if="$helper.checkUserHasPermission(route, 'delete')"
							src="@/assets/images/icons/delete.svg"
							@click="deleteRegister(slotProps.data.id, slotProps.data.active)"
						/>
					</Button>
				</template>
			</Column>
		</template>
	</TableDefault>
</template>

<script>
	import Column from "primevue/column";
	import { mapState, mapActions, mapMutations } from "vuex";

	export default {
		components: {
			Column,
		},
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data() {
			return {
				filters: [
					{
						name: "buscar",
						value: null,
						type: "string",
						placeholder: "Buscar...",
					},
					{
						name: "from",
						value: null,
						type: "date",
						placeholder: "Desde",
					},
					{
						name: "to",
						value: null,
						type: "date",
						placeholder: "Hasta",
					},
                    {
						name: "button_filter",
						value: 0
					}
				],
			};
		},
		methods: {
			...mapActions(["deleteRegisters", "getList", "publishGame"]),
			...mapMutations([
				"toggleFormDialog",
				"changeFormDialogMode",
				"changeCurrentRegister",
				"toggleInfoDialog",
				"toggleInfoFaceToFaceDialog",
				"toggleFormLanguageDialog"
			]),
			showRegister(e, type) {
				const classList = e.originalEvent?.target.classList;

				if(type == 'language'){
					const register = e.data != undefined ? e.data : e;

					this.changeCurrentRegister({
						stateVariable: this.stateVariable,
						register,
					});
					this.toggleFormLanguageDialog({
						stateVariable: this.stateVariable,
						show: true,
					});

					this.$emit("showRegister", register, type);

				}else  if (
					(classList == undefined || !classList?.contains("download-link")) &&
					!classList?.contains("p-checkbox-icon")
				) {
					const register = e.data != undefined ? e.data : e;

					this.changeCurrentRegister({
						stateVariable: this.stateVariable,
						register,
					});

					this.changeFormDialogMode({
						stateVariable: this.stateVariable,
						dialogMode: type,
					});

					if(this.stateVariable == 'face_to_face_games'){
						if(type == 'show'){
							this.toggleInfoFaceToFaceDialog({
								stateVariable: this.stateVariable,
								show: true,
							});

						}else{
							this.toggleFormDialog({
								stateVariable: this.stateVariable,
								show: true,
							});
						}
						
					}else{
						this.toggleFormDialog({
							stateVariable: this.stateVariable,
							show: true,
						});
					}

					this.$emit("showRegister", register, type);
				}
			},
			deleteRegister(id) {
				this.$confirm.require({
					message: "¿Desea borrar el registro?",
					header: "Borrar registro",
					icon: "pi pi-exclamation-triangle",
					accept: () => {
						this.deleteRegisters({
							url: "/delete-game/" + id
						}).then(() => {
							this.$refs.table.getList();
						});
					},
				});
			},
			publishGameAction(id){
				this.publishGame({
					url: "/publish-game/" + id
				}).then(() => {
					this.$refs.table.getList();
				});
			}
		},
		computed: {
			...mapState(["face_to_face_games"]),
		},
	};
</script>

<style lang="scss" scoped>
	.p-avatar.not-active, .rol.not-active {
		background-color: #ABAFB3 !important;
		color: white !important;
		border-width: 0px;
	}

	.f-12{
		font-size: 12px;
	}

	.f-14{
		font-size: 14px;
	}

	.br-8{
		border-radius: 8px !important;
	}

	.gray-color{
		color: #B5B5C3; 
	}

	.fw-500{
		font-weight: 500;
	}

	.border-1{
		border-width: 1px;
		border-style: solid;
	}

	.not-active {
		filter: grayscale(100%);
	}
</style>
