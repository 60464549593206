const mutations = {
    // General
    changeUserLogged(state, n) {
        state.userLogged = n;
    },
    toggleSidebar(state, n) {
        state.showSidebar = n;
    },
    toggleUpdatePassword(state, n) {
        state.showUpdatePassword = n;
    },
    toggleUpdatePasswordDialog(state, n) {
        state.updatePasswordDialog = n;
    },

    // Toasts
    changeSuccessToast(state, n) {
        state.successToast = n;
    },
    changeErrorToast(state, n) {
        state.errorToast = n;
    },
    changeWarningToast(state, n) {
        state.warningToast = n;
    },

    // Defaults
    changeCurrentTablePage(state, n) {
        state[n.stateVariable].currentPage = n.event.page;
        state[n.stateVariable].rows = n.event.rows;
    },
    toggleFormDialog(state, n) {
        state[n.stateVariable].dialog = n.show;
    },
    toggleFormLanguageDialog(state, n) {
        state[n.stateVariable].dialog_language = n.show;
    },
    changeFormDialogMode(state, n) {
        state[n.stateVariable].dialogMode = n.dialogMode;
    },
    changeCurrentRegister(state, n) {
        state[n.stateVariable].register = n.register;
    },

    // Users
    toggleInfoDialog(state, n) {
        state.users.infoDialog = n;
    },

    // Face to face
    toggleInfoFaceToFaceDialog(state, n) {
        state.face_to_face_games.infoDialog = n;
    },

    // Challenges
    toggleShowHints(state, n) {
        state.challenges.showHints = n;
    },
};

export default mutations;