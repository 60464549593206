import { createWebHistory, createRouter } from "vue-router";

import axios from "./axios";
import store from "./store";

// Layouts
import AuthLayout from "@/components/layouts/AuthLayout.vue";
import AdminLayout from "@/components/layouts/AdminLayout.vue";
//import WebLayout from "@/components/layouts/WebLayout.vue";
import VerifiedAccount from "@/components/auth/VerifiedAccount.vue";

// Web
/*import Home from "@/components/web/home/Home.vue";
import Challenge from "@/components/web/challenge/Challenge.vue";
import Profile from "@/components/web/profile/Profile.vue";
import Ranking from "@/components/web/ranking/Ranking.vue";*/

// Admin
// import Roles from "@/components/roles/Roles.vue";
import Users from "@/components/users/Users.vue";
import GameTables from "@/components/game_tables/GameTables.vue";
import FaceToFaceGames from "@/components/face_to_face_games/FaceToFaceGames.vue";
import PurchaseMade from "@/components/purchase_made/PurchaseMade.vue";

import Challenges from "@/components/challenges/Challenges.vue";
import Categories from "@/components/categories/Categories.vue";
import Ads from "@/components/ads/Ads.vue";
import Logs from "@/components/logs/Logs.vue";
// import Orders from "@/components/orders/Orders.vue";
// import Demo from "@/components/demo/Demos.vue";

const routes = [
    /*{
        path: "/",
        component: WebLayout,
        beforeEnter: checkUserLogged,
        children: [{
                path: "",
                name: "Inicio",
                component: Home,
                meta: {
                    title: "Inicio",
                },
            },
            {
                path: "/challenge/:id",
                name: "Reto",
                component: Challenge,
                meta: {
                    title: "Reto",
                },
            },
            {
                path: "/profile",
                name: "Perfil",
                component: Profile,
                meta: {
                    title: "Perfil",
                },
            },
            {
                path: "/ranking",
                name: "Ranking",
                component: Ranking,
                meta: {
                    title: "Ranking",
                },
            },
        ],
    },*/
    {
        path: "/",
        component: AuthLayout,
        beforeEnter: checkLogin,
        name: "login",
    },
    {
        path: "/password/reset/:token",
        component: AuthLayout,
        name: "PasswordReset",
    },
    {
        path: "/verfied-account",
        component: VerifiedAccount,
        name: "VerifiedAccount",
    },
    {
        path: "/auth/:provider/callback",
        component: {
            template: '<div class="auth-component"></div>',
        },
    },
    {
        path: "/admin",
        component: AdminLayout,
        beforeEnter: checkUserLogged,
        children: [{
                path: "users",
                name: "Usuarios",
                component: Users,
                meta: {
                    title: "Usuarios",
                },
            },
            {
                path: "game_tables",
                name: "Mesas de juegos",
                component: GameTables,
                meta: {
                    title: "Mesas de juegos",
                },
            },
            {
                path: "face_to_face_games",
                name: "Juegos presenciales",
                component: FaceToFaceGames,
                meta: {
                    title: "Juegos presenciales",
                },
            },
            {
                path: "purchase_made",
                name: "Compras realizadas",
                component: PurchaseMade,
                meta: {
                    title: "Compras realizadas",
                },
            },
            {
                path: "logs",
                name: "Logs",
                component: Logs,
                meta: {
                    title: "Logs",
                },
            },



            {
                path: "challenges",
                name: "Retos",
                component: Challenges,
                meta: {
                    title: "Retos",
                },
            },
            {
                path: "categories",
                name: "Categorías",
                component: Categories,
                meta: {
                    title: "Categorías",
                },
            },
            {
                path: "ads",
                name: "Publicidad",
                component: Ads,
                meta: {
                    title: "Anuncios",
                },
            },
            /*{
                path: "orders",
                name: "Compras realizadas",
                component: Orders,
                meta: {
                    title: "Compras realizadas",
                },
            },*/

            // {
            //   path: "demo",
            //   name: "Demo",
            //   component: Demo,
            // },
            // {
            //   path: "roles",
            //   name: "Roles",
            //   component: Roles,
            // },
        ],
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: "/",
    },
];

function checkLogin(to, from, next) {
    if (localStorage.getItem("accessToken") != null) {
        next({ path: "/admin/users" });
    } else {
        next();
    }

    return;
}

function checkUserLogged(to, from, next) {
    if (localStorage.getItem("accessToken") == null) {
        next({ path: "/login" });

        return;
    }

    axios.defaults.headers.common[
        "Authorization"
    ] = `Bearer ${localStorage.getItem("accessToken")}`;

    axios
        .post("/check-user-logged")
        .then((response) => {
            store.commit("changeUserLogged", response.data.user);

            if (to.path === "/login") {
                next({ path: "/admin/users" });
            } else {
                next();
            }
        })
        .catch((error) => {
            console.error(
                "Router JS ~ checkAdminRights",
                error.response,
                //error.response ? .data ? .message
            );

            if (error.response.data.message === "CSRF token mismatch.") {
                location.reload();
            }

            if (
                error.response.data.message == "User not found" ||
                error.response.data.message == "Unauthorized" ||
                error.response.data.message == "Unauthenticated."
            ) {
                window.localStorage.clear();
                next({ path: "/login" });
            }
        });

    return;
}

const router = new createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    // Get the page title from the route meta data that we have defined
    // See further down below for how we setup this data
    const title = to.meta.title;

    //Take the title from the parameters
    const titleFromParams = to.params.pageTitle;
    // If the route has a title, set it as the page title of the document/page
    if (title) {
        document.title = title;
    }
    // If we have a title from the params, extend the title with the title
    // from our params
    if (titleFromParams) {
        document.title = `${titleFromParams} - ${document.title}`;
    }
    // Continue resolving the route
    next();
});

export default router;