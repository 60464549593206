import Consts from "../consts";

const defaults = [
    "roles",
    "permissions",
    "users",
    "game_tables",
    "face_to_face_games",
    "face_to_face_objects",
    "purchase_made",
    "logs",
    "challenges",
    "categories",
    "hints",
    "ads",
    "orders",
    "buy_hints",
    "use_hints",
    "languages"
];

const state = {
    baseURL: Consts.defaultApiRoute,
    showSidebar: false,
    userLogged: null,
    showUpdatePassword: false,
    updatePasswordDialog: false,

    // Datatable defaults
    datatableDefaults: {
        noResults: "No hay resultados",
        lazy: true,
        loading: true,
        paginator: true,
        rows: 5,
        rowsPerPageOptions: [5, 10, 20],
        rowHover: true,
        scrollable: true,
        scrollHeight: "600px",
        currentPage: 0,
        paginatorTemplate: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
        currentPageReportTemplate: "Mostrando del {first} al {last} de un total de {totalRecords} registros",
        responsiveLayout: "stack",
        breakpoint: "960px",
    },

    // Dialogs defaults
    dialogDefaults: {
        modal: true,
        closeOnEscape: true,
        dismissableMask: true,
    },

    // Challenges details
    challenges_error: {
        dialog: false,
    },
    challenges_success: {
        dialog: false,
    },
    challenges_instructions: {
        dialog: false,
    },
};

for (const item of defaults) {
    state[item] = {
        // Table
        list: [],
        listTotal: 0,
        currentPage: 0,
        rows: 5,

        // Form
        register: null,
        dialog: false,
        dialogMode: "show",
        dialog_language: false
    };
}

state.users.infoDialog = null;
state.challenges.showHints = false;

state.challenges.levels = [{
        name: "Fácil",
        value: "easy",
    },
    {
        name: "Medio",
        value: "medium",
    },
    {
        name: "Difícil",
        value: "hard",
    },
];

export default state;