<template>
	<div class="card border-radius p-3 border-0 box-shadow">
		<div class="row">
			<div class="col-12 col-md-6 border-right">
				<div class="d-flex flex-column">
					<div class="d-flex gap-3">
						<div>
							<div class="border-radius overflow-hidden">
								<img
									v-if="challenges.register.image"
									:src="challenges.register.image"
									height="150"
								/>
								<img v-else src="@/assets/images/no-image.png" height="150" />
							</div>
						</div>
						<div class="d-flex flex-column">
							<h4 class="fw-bold">{{ challenges.register.name }}</h4>
							<p>{{ challenges.register.category.name }}</p>
							<Attributes
								class="d-none d-md-flex"
								:challenge="challenges.register"
							/>
						</div>
					</div>
				</div>
				<div>
					<hr />
					<div class="row">
						<div class="col-12 col-md-4 mt-2 mt-md-0">
							<div
								class="d-flex flex-column small align-items-center text-center"
							>
								<div class="fw-bold">Premio</div>
								<div class="text-muted">{{ challenges.register.prize }}</div>
							</div>
						</div>
						<div class="col-12 col-md-4 mt-2 mt-md-0">
							<div
								class="d-flex flex-column small align-items-center text-center"
							>
								<div class="fw-bold">Fecha</div>
								<div class="text-muted">
									{{
										$helper.formatDate(challenges.register.date, "DD/MM/YYYY")
									}}
								</div>
							</div>
						</div>
						<div class="col-12 col-md-4 mt-2 mt-md-0">
							<div
								class="d-flex flex-column small align-items-center text-center"
							>
								<div class="fw-bold">Hora</div>
								<div class="text-muted">
									{{ $helper.removeSeconds(challenges.register.time) }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<Attributes
					class="d-flex d-md-none mt-3"
					:challenge="challenges.register"
				/>
			</div>
			<div class="col-12 col-md-6 mt-3 mt-md-0">
				<div class="d-flex flex-column">
					<b>{{ challenges.register.statement }}</b>
					{{ challenges.register.solution }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Attributes from "@/components/challenges/hints/Attributes.vue";
	import { mapState } from "vuex";

	export default {
		components: {
			Attributes,
		},
		computed: {
			...mapState(["challenges"]),
		},
	};
</script>

<style lang="scss" scoped>
	.card {
		border-top: 3px solid $primary !important;
	}

	.border-right {
		border-right: 1px solid #e0e0e0;
	}

	@media (min-width: $mobile-min-width) and (max-width: $mobile-max-width) {
		.border-right {
			border-right: none;
		}
	}
</style>
