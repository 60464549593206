<template>
	<Dialog
		v-model:visible="$store.state.face_to_face_games.infoDialog"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '25vw' }"
		:modal="dialogDefaults.modal"
		:closeOnEscape="dialogDefaults.closeOnEscape"
		:dismissableMask="dialogDefaults.dismissableMask"
		@hide="hide"
		@show="show"
	>
		<template #header>
			<FormTitle title="Ficha del juego" class="f-24"/>
		</template>

		<div class="row pt-3">
			<div class="col-12 justify-content-between d-flex">
				<div class="col-2"><img class="mb-auto" src="@/assets/images/icons/avatar_description_game.svg" width="52" /></div>
				<div class="col-10 d-flex gap-3 my-auto">
					<label class="f-500">{{ this.game?.name }}</label>
				</div>
			</div>
            <div class="col-12 d-flex justify-content-between">
                <div class="col-2"></div>
				<div class="col-10 d-flex gap-3">
					<div class="d-flex flex-column">
						<label v-html="this.game?.guide_text" />
                        <!--<label class="f-500 f-14">{{ this.game?.guide }}</label>-->
					</div>
				</div>
			</div>
            <div class="col-12 d-flex justify-content-between mt-3">
                <div class="col-2"></div>
                <div class="d-flex flex-column col-10 my-auto">
                    <label class="f-500">Objetos: </label>
					<div class="d-flex">
						<div v-for="(object, index) in this.game?.array_objects" :key="index" class="bg-light-primary border-primary text-primary py-1 br-8 f-12 col-2 text-align-center br-25 mt-2 mr-10-c">
							{{ object.name }}
						</div>
					</div>
                </div>
            </div>
		</div>

		<template #footer>
			<div class="col-12 d-flex justify-content-center">
				<Button
					@click="toggleInfoFaceToFaceDialog(false)"
					label="Cerrar"
					class="btn-primary"
				/>
			</div>
		</template>
	</Dialog>
</template>

<script>
	import { mapMutations, mapState } from "vuex";

	export default {
		components: {
		},
		data: () => ({
			game: null,
		}),
		methods: {
			...mapMutations(["toggleInfoFaceToFaceDialog"]),
			show() {
				this.game = null;
				this.game = this.face_to_face_games.register;
			},
			hide() {
				this.game = null;
				/*this.changeCurrentRegister({
					stateVariable: this.stateVariable,
					register: null,
				});*/
			}
		},
		computed: {
			...mapState(["dialogDefaults", "face_to_face_games"]),
		},
	};
</script>

<style lang="scss" scoped>
	:deep(.p-knob-text) {
		font-size: 1rem;
	}

	.mr-10-c{
		margin-right: 10px;
	}
</style>
