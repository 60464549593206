<template>
	<form @keydown="$event.key === 'Enter' ? save() : null">
		<div class="row">
			<div class="col-12 h1 text-blue title">Recuperar contraseña</div>
			<p @click="$parent.showForm = 'login'" class="cursor-pointer text-blue">
				<b>Volver al inicio de sesión</b>
			</p>
			<div class="col-12">
				<InputText
					label="Email*"
					placeholder="Introduce tu email"
					:error="form.errors.get('email')"
					@change-value="(value) => (form.email = value)"
				/>
			</div>
			<InputText class="d-none" />
			<div class="col-12 d-flex mt-4">
				<Button
					@click="save()"
					label="Enviar"
					class="w-100 btn-primary"
					:disabled="form.busy"
				/>
			</div>
		</div>
	</form>
</template>

<script>
	import Form from "vform";
	import { mapActions } from "vuex";

	export default {
		data() {
			return {
				form: new Form(),
			};
		},
		methods: {
			...mapActions(["sendForm"]),
			save() {
				const url = `/send-forget-password-email`;

				this.sendForm({
					method: "post",
					form: this.form,
					url: url,
					errors: this.form.errors,
				}).then((response) => {
					if (response.status === 200) {
						this.$parent.showSendForgotPasswordEmail();

						this.$nextTick(() => {
							this.$parent.showForm = "login";
						});
					} else {
						this.$parent.showError();
					}
				});
			},
		},
	};
</script>


<style lang="scss" scoped>
	.text-blue{
		color: #218BF4;
	}
</style>