<template>
	<div>
		<div class="d-none d-md-flex align-items-center text-primary-dark h4 mb-0 px-2 pb-4">
			<b>{{ $route.name }}</b>
		</div>
		<PurchaseMadeTable :route="route" :stateVariable="stateVariable" />
	</div>
</template>

<script>
	import PurchaseMadeTable from "./PurchaseMadeTable.vue";

	export default {
		components: {
			PurchaseMadeTable
		},
		data() {
			return {
				route: "purchase_made",
				stateVariable: "purchase_made",
			};
		},
	};
</script>
<style lang="scss" scoped>
	
</style>