<template>
	<div>
		<div class="d-none d-md-flex align-items-center text-primary-dark h4 mb-0 px-2 pb-4">
			<b>{{ $route.name }}</b>
		</div>
		<LogsTable :route="route" :stateVariable="stateVariable" />
	</div>
</template>

<script>
	import LogsTable from "./LogsTable.vue";

	export default {
		components: {
			LogsTable,
		},
		data() {
			return {
				route: "logs",
				stateVariable: "logs",
			};
		},
	};
</script>
