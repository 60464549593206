<template>
	<Dialog
		v-model:visible="$store.state.users.dialog"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '25vw' }"
		:modal="dialogDefaults.modal"
		:closeOnEscape="dialogDefaults.closeOnEscape"
		:dismissableMask="dialogDefaults.dismissableMask"
		@hide="hide"
		@show="show"
	>
		<template #header>
			<FormTitle :title="title" />
		</template>

		<form @keydown="$event.key === 'Enter' ? save() : null">
			
			<div class="row gy-3 pt-1">
				<div class="col-12 text-align-center align-items-center gap-2" v-if="users.dialogMode == 'edit'">
					<Avatar
						style="width: 84px; height: 84px;"
						class="bg-light-primary text-primary border-radius f-24 mx-auto br-50"
						:label="label_avatar"
					/>
					<div class="mt-3">
						<span class="text-black">{{form.name}}</span>
					</div>
					<div class="my-1">
						<span class="f-14 text-dark-grey">{{form.email}}</span>
					</div>
				</div>
				<div class="col-12">
					<InputText
						ref="name"
						label="Nombre *"
						:disabled="disabled"
						:error="form.errors.get('name')"
						class="bg-inputs"
						:placeholder="'Introducir nombre'"
						@change-value="(value) => (form.name = value)"
					/>
				</div>
				<div class="col-12">
					<InputText
						ref="email"
						label="Email *"
						:disabled="disabled"
						:error="form.errors.get('email')"
						class="bg-inputs"
						:placeholder="'Introducir email'"
						@change-value="(value) => (form.email = value)"
					/>
				</div>
				<div class="col-12">
					<div>
						<label style="color: #000B33 !important">Rol*</label>
						<div class="d-flex flex-wrap mr-2 mt-3">
							<div class="w-50" v-for="(rol, rolIndex) in roles.list" :key="rolIndex">
								<div class="flex align-items-center bg-radio mr-4 pyc-17 br-29 text-grey" :class="{'selected' : checked==rol.id}">
									<RadioButton v-if="disabled" disabled :error="form.errors.get('role_id')" @change-value="(value) => (form.role_id = value)" v-model="checked" class="ml-4" inputId="{{ rol.name }}" ref="role_id" :value="rol.id"/>
									<RadioButton v-else :error="form.errors.get('role_id')" @change-value="(value) => (form.role_id = value)" v-model="checked" class="ml-4" inputId="{{ rol.name }}" ref="role_id" :value="rol.id"/>
									<label for="{{ rol.name }}" class="ml-2 w-50">{{ rol.slug }}</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>

		<template #footer v-if="!disabled">
			<div class="col-12 d-flex justify-content-between">
				<Button
					@click="
						toggleFormDialog({
							stateVariable: stateVariable,
							value: false,
						})
					"
					label="Cancelar"
					:disabled="form.busy"
					class="bg-white border btn-secundary"
				/>
				<Button @click="save()" class="btn-primary" :label="label_button" :disabled="form.busy" />
			</div>
		</template>
	</Dialog>
</template>

<script>
	import Avatar from "primevue/avatar";
	import RadioButton from 'primevue/radiobutton';
	import Form from "vform";
	import { mapActions, mapMutations, mapState } from "vuex";

	export default {
		components: {
			RadioButton,
			Avatar
		},
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data: () => ({
			form: new Form(),
			modelName: "usuario",
			title: `Añadir usuario`,
			disabled: false,
			lastPageChange: null,
			checked: 1,
			label_button: "Añadir",
			label_avatar: ''
		}),
		methods: {
			...mapActions(["sendForm", "getRegisters"]),
			...mapMutations(["toggleFormDialog", "changeCurrentRegister"]),
			save() {
				const update = this.users.register != null;
				const url = `/users${update ? `/${this.users.register.id}` : ""}`;
				this.form.role_id = this.checked;

				this.sendForm({
					method: update ? "put" : "post",
					form: this.form,
					url: url,
					errors: this.form.errors,
				}).then((response) => {
					if (response.status === 200) {
						this.toggleFormDialog({
							stateVariable: this.stateVariable,
							value: false,
						});

						this.getRegisters({
							route: this.route,
							stateVariable: this.stateVariable,
							page: this.users.currentPage,
							rows: this.users.rows,
						});
					}
				});
			},
			hide() {
				this.title = `Añadir ${this.modelName}`;
				this.label_button = 'Añadir';
				this.disabled = false;
				this.checked = 1;
				this.users.dialogMode = 'add';

				this.changeCurrentRegister({
					stateVariable: this.stateVariable,
					register: null,
				});
			},
			clearForm() {
				this.form.clear();
				this.form.reset();

				for (const key in this.$refs) {
					if (Object.hasOwnProperty.call(this.$refs, key)) {
						this.$refs[key].model = null;
					}
				}
			},
			show() {
				this.clearForm();

				this.getRegisters({
					route: "/roles",
					stateVariable: "roles",
					getAll: true,
					showLoading: false,
				});

				const register = this.users.register;

				if (register != null) {
					for (const key in register) {
						if (
							Object.hasOwnProperty.call(register, key) &&
							this.$refs[key] != undefined
						) {
							this.$refs[key].model = register[key];
						}
					}
					this.label_avatar = this.firstTwoLetters(this.$refs['name'].model);
					this.checked = register.role_id;

					if (this.users.dialogMode == "edit") {
						this.title = `Modificar ${this.modelName}`;
						this.label_button = 'Guardar';
						this.disabled = false;
					} else {
						this.title = `Ver ${this.modelName}`;
						this.disabled = true;
					}
				}
			},
			firstTwoLetters(name) {
				if(name !== undefined){
					return name.substring(0, 2);
				}
			},
		},
		computed: {
			...mapState(["dialogDefaults", "users", "roles"]),
		}
	};
</script>
<style lang="scss" scoped>
	.ml-2{
		margin-left: 0.5rem;
	}

	.mr-4{
		margin-right: 1rem;
	}

	.ml-4{
		margin-left: 1rem;
	}

	.pyc-17{
		padding-top: 17px;
		padding-bottom: 17px;
	}

	.br-29{
		border-radius: 29px;
	}

	.br-29.selected{
		border-width: 1px;
		border-color: $primary;
		border-style: solid;
		color: #464E5F !important;
	}

	.br-50{
		border-radius: 50%;
	}
	
</style>