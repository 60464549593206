<template>
	<TableDefault
		ref="table"
		:route="'get-games-tables'"
		:stateVariable="stateVariable"
		:list="game_tables.list"
		:total="game_tables.listTotal"
		:filters="filters"
		:type_filter="0"
		:delete="'game_tables'"
		:showActionsColumn="false"
		:showDeleteButton="false"
		:showTitle="false"
		:addButtonText="''"
		:showSelectMultipleColumn="false"
	>
		<template #columns>
			<Column header="CREADOR DE MESA">
				<template #body="slotProps">
					<div class="d-flex align-items-center gap-2">
						<Avatar
							:class="{ 'not-active': !slotProps.data.active }"
							class="bg-light-primary text-primary border-radius f-12"
							:label="firstTwoLetters(slotProps.data.name)"
						/>

						<b>{{ slotProps.data.name }}</b>
					</div>
				</template>
			</Column>
			<Column header="JUGADORES REALES">
				<template #body="slotProps">
					<span class="fw-500">
						{{ slotProps.data.num_players }}
					</span>
				</template>
			</Column>
			<Column header="FECHA">
				<template #body="slotProps">
					<span class="gray-color">
						{{ $helper.formatDate(slotProps.data.created_at, "DD/MM/YYYY") }}
					</span>
				</template>
			</Column>
		</template>
	</TableDefault>
</template>

<script>
	import Avatar from "primevue/avatar";
	import Column from "primevue/column";
	import { mapState, mapActions, mapMutations } from "vuex";

	export default {
		components: {
			Column,
			Avatar,
		},
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data() {
			return {
				filters: [
					{
						name: "buscar",
						value: null,
						type: "string",
						placeholder: "Buscar...",
					},
					{
						name: "from",
						value: null,
						type: "date",
						placeholder: "Desde",
					},
					{
						name: "to",
						value: null,
						type: "date",
						placeholder: "Hasta",
					},
                    {
						name: "button_filter",
						value: 0
					}
				],
			};
		},
		methods: {
			...mapActions(["deleteRegisters", "getList"]),
			...mapMutations([
				"toggleFormDialog",
				"changeFormDialogMode",
				"changeCurrentRegister",
				"toggleInfoDialog",
			]),
			firstTwoLetters(name) {
				return name.substring(0, 2);
			},
		},
		computed: {
			...mapState(["game_tables"]),
		},
	};
</script>

<style lang="scss" scoped>
	.p-avatar.not-active, .rol.not-active {
		background-color: #ABAFB3 !important;
		color: white !important;
		border-width: 0px;
	}

	.f-12{
		font-size: 12px;
	}

	.f-14{
		font-size: 14px;
	}

	.br-8{
		border-radius: 8px !important;
	}

	.gray-color{
		color: #B5B5C3; 
	}

	.fw-500{
		font-weight: 500;
	}

	.border-1{
		border-width: 1px;
		border-style: solid;
	}

	.not-active {
		filter: grayscale(100%);
	}
</style>
