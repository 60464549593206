<template>
	<Dialog
		v-model:visible="$store.state.face_to_face_games.dialog_language"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '40vw' }"
		:modal="dialogDefaults.modal"
		:closeOnEscape="dialogDefaults.closeOnEscape"
		:dismissableMask="dialogDefaults.dismissableMask"
		@hide="hide"
		@show="show"
	>
		<template #header>
			<FormTitle :title="title" />
		</template>

		<form @keydown="$event.key === 'Enter' ? save() : null">
			
			<div class="row gy-3 pt-1">
				<div class="col-12 d-block">
					<label for="input" class="text-primary" style="margin-bottom: 16px;">Idioma *</label>
					<div>
						<Dropdown v-model="language" :options="filteredLanguages" optionLabel="name" optionValue="id" inputStyle="color: #6c757d;font-weight 400 !important; padding-left: 15px;" placeholder="Selecciona un idioma" class="w-100 br-25 bg-inputs-div" />
						<div v-if="form.errors.get('order')" class="text-danger">Debes añadir al menos un orden.</div>
					</div>
				</div>
				<div class="col-12">
					<InputText
						ref="name"
						label="Nombre del juego *"
						v-model="name"
						:disabled="disabled"
						:error="form.errors.get('name')"
						class="bg-inputs"
						:placeholder="'Introducir nombre'"
						@change-value="(value) => (form.name = value)"
					/>
				</div>

				<div class="col-12 d-block">
					<label for="input" class="text-primary" style="margin-bottom: 16px;">Guía *</label>
					<Editor :placeholder="'Introducir una descripción'" v-model="guide" ref="guide" :disabled="disabled" editorStyle="height: 320px" />
					<div v-if="form.errors.get('guide')" class="text-danger">El campo guía es obligatorio.</div>
				</div>
			</div>
		</form>

		<template #footer v-if="!disabled">
			<div class="col-12 d-flex justify-content-between">
				<Button
					@click="
						toggleFormLanguageDialog({
							stateVariable: stateVariable,
							value: false,
						})
					"
					label="Cancelar"
					:disabled="form.busy"
					class="bg-white border btn-secundary"
				/>
				<Button @click="save()" class="btn-primary" :label="label_button" :disabled="form.busy" />
			</div>
		</template>
	</Dialog>
</template>

<script>
	import Form from "vform";
	import Dropdown from "primevue/dropdown";
	import Editor from 'primevue/editor';

	import { mapActions, mapMutations, mapState } from "vuex";

	export default {
		components: {
			Dropdown,
			Editor
		},
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data: () => ({
			form: new Form(),
			modelName: "juego",
			title: `Añadir juego`,
			disabled: false,
			lastPageChange: null,
			label_button: "Añadir",
			guide: null,
			guide_text: '',
			game_id: 0,
			language: null
		}),
		methods: {
			...mapActions(["sendForm", "getLanguages", "getRegisters"]),
			...mapMutations(["toggleFormLanguageDialog", "changeCurrentRegister"]),
			save() {
				var url = "/create-translation-game";
				this.form.game_id = this.game_id;
				this.form.language_id = this.language;
				this.form.guide = this.guide;
				this.form.guide_text = this.guide_text;

				this.sendForm({
					method: "post",
					form: this.form,
					url: url,
					errors: this.form.errors,
				}).then((response) => {
					if (response.status === 200) {
						this.toggleFormLanguageDialog({
							stateVariable: this.stateVariable,
							value: false,
						});

						this.getRegisters({
							route: 'get-games',
							stateVariable: this.stateVariable,
							page: this.face_to_face_games.currentPage,
							rows: this.face_to_face_games.rows,
						});
					}
				});
			},
			hide() {
				this.title = `Añadir ${this.modelName}`;
				this.label_button = 'Añadir';
				this.disabled = false;
				this.face_to_face_games.dialogMode = 'add';

				this.changeCurrentRegister({
					stateVariable: this.stateVariable,
					register: null,
				});
			},
			clearForm() {
				this.form.clear();
				this.form.reset();

				for (const key in this.$refs) {
					if (Object.hasOwnProperty.call(this.$refs, key)) {
						this.$refs[key].model = null;
					}
				}
				this.guide = "";
				this.guide_aux = "";			
			},
			show() {
				this.clearForm();

				//Cargamos en el select los objetos
				this.getLanguages();
				const register = this.face_to_face_games.register;
				if(register != null){
					this.language = 2;
					this.game_id = register.id;
					this.fillForm(this.language_id);
				}
				
			},
			fillForm(language_id){
				if(language_id == undefined){
					language_id = 2;
				}
				if(this.face_to_face_games.register != null){
					if(this.face_to_face_games.register.games_languages.length > 0){
						//Inicializamos los componentes
						this.name = null;
						this.guide = '';
						this.guide_text = '';
						for (const key in this.$refs) {
							if (Object.hasOwnProperty.call(this.$refs, key)) {
								this.$refs[key].model = null;
							}
						}

						this.face_to_face_games.register.games_languages.forEach(language => {
							this.disabled = false;
							if(language_id == 1){
								this.disabled = true;
							}
							if(language.language_id == language_id){
								this.guide = language.guide;
								this.guide_text = language.guide_text;
								for (const key in language) {
									if (
										Object.hasOwnProperty.call(language, key) &&
										this.$refs[key] != undefined &&
										language.language_id == language_id
									) {
										this.$refs[key].model = language[key];
									}
								}
							}
						});
					}
				}
			}
		},
		computed: {
			...mapState(["dialogDefaults", "face_to_face_games", "languages"]),

			//Filtramos que no aparezca el Español
			filteredLanguages() {
				// Filtrar el idioma que no deseas mostrar, por ejemplo, con id = 2
				return this.languages.list.filter(language => language.id !== 1);
			},
		},
		mounted() {
			this.fillForm(this.language);
		},
		watch: {
			guide: {
				handler(value) {
					var regex = /(&nbsp;|<([^>]+)>)/ig
					var result = value.replace(regex, "");
					this.guide_text = result;
				}
			},
			language: {
				handler(value) {
					//Rellenamos el formulario al cambiar de idioma
					this.fillForm(value);
				}
			},
		}
	}
</script>
<style lang="scss" scoped>
	.ml-2{
		margin-left: 0.5rem;
	}

	.mr-4{
		margin-right: 1rem;
	}

	.ml-4{
		margin-left: 1rem;
	}

	.pyc-17{
		padding-top: 17px;
		padding-bottom: 17px;
	}

	.br-29{
		border-radius: 29px;
	}

	.br-29.selected{
		border-width: 1px;
		border-color: $primary;
		border-style: solid;
		color: #464E5F !important;
	}

	.br-50{
		border-radius: 50%;
	}
	.span.p-dropdown-label.p-inputtext.p-placeholder{
		padding-left: 40px !important;
	}

	div.p-dropdown-trigger>span.p-dropdown{
		color: #6c757d !important;
	}
	.p-dropdown.p-dropdown-label{
		color: #6c757d !important;
	}
</style>