<template>
	<div id="app">
		<router-view></router-view>
		<div
			v-if="
				$route.name != 'login' &&
				$route.name != 'PasswordReset' &&
				$route.name != 'Inicio' &&
				$route.name != 'Reto' &&
				$route.name != 'Perfil' &&
				$route.name != 'Ranking' &&
				$route.name != 'VerifiedAccount'
			"
			class="d-flex align-items-center justify-content-center loading"
			:class="[userLogged != null ? 'hide' : '']"
		>
			<img src="@/assets/images/logo_login.svg" width="300" />
		</div>
	</div>
</template>

<script>
	import { mapState } from "vuex";

	export default {
		computed: {
			...mapState(["userLogged"]),
		},
	};
</script>

<style lang="scss" scoped>
	.loading {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 100;
		background-color: white;

		&.hide {
			animation: hide 0.5s ease-out forwards;
			animation-delay: 0.3s;

			@keyframes hide {
				from {
					opacity: 1;
				}

				to {
					opacity: 0;
					visibility: hidden;
				}
			}
		}

		img {
			animation: blink 1s ease infinite alternate;

			@keyframes blink {
				from {
					opacity: 1;
				}

				to {
					opacity: 0.3;
				}
			}
		}
	}
</style>
