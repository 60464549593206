<template>
	<form @keydown="$event.key === 'Enter' ? save() : null">
		<div class="row">
			<div class="col-12 h1 text-blue title mb-3 mb-md-1">
				<b>Iniciar sesión</b>
			</div>
			<div class="col-12">
				<InputText
					label="Email*"
					placeholder="Introduce tu email"
					:error="form.errors.get('email')"
					@change-value="(value) => (form.email = value)"
					:type="'email'"
					:class="text-primary-custom"
					class="bg-inputs"
				/>
			</div>
			<div class="col-12 mt-4">
				<InputPassword
					label="Contraseña*"
					placeholder="Contraseña"
					:error="form.errors.get('password')"
					:feedback="false"
					forgot_password="1"
					@change-value="(value) => (form.password = value)"
				/>
				
			</div>
			<div class="col-12 mt-4">
				<CheckBox
					label="Recuerdame"
					:error="form.errors.get('terms_and_conditions')"
					@change-value="(value) => (form.terms_and_conditions = value)"
					:binary="true"
				/>
			</div>
			<div class="col-12 d-flex mt-3 mt-md-4 row pr-0">
				<div class="col-12 px-0">
					<Button
						:disabled="form.busy"
						@click="save()"
						label="Iniciar sesión"
						class="w-100 btn-primary"
					/>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
	import { Providers } from "universal-social-auth";
	import Form from "vform";
	import { mapActions } from "vuex";

	export default {
		data: () => ({
			form: new Form(),
			showForgotPassword: false,
			showRegister: false,
			showForm: ''
		}),
		methods: {
			...mapActions(["login"]),
			save() {
				this.login({
					form: this.form,
					errors: this.form.errors,
				});
			},
			useAuthProvider(provider, proData) {
				const pro = proData;

				const ProData = pro || Providers[provider];
				this.$Oauth
					.authenticate(provider, ProData)
					.then((response) => {
						const rsp = response;
						if (rsp.code) {
							response.data.value.code = rsp.code;
							response.data.value.provider = provider;
							this.useSocialLogin();
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			async useLoginFirst(e) {
				// this sample of how to pust user data to my store
				const firstlogin = await this.$auth.firstlogin(e);
				if (firstlogin) {
					await this.$router.push({ name: "dashboard" });
					return;
				}
			},
			useSocialLogin(response, data, hash, fauth) {
				// otp from input Otp form
				// hash user data in your backend with Cache or save to database
				const pdata = {
					code: response.data.value.code,
					otp: data.value.tok,
					hash: hash.value,
				};
				this.$axios
					.post("/social-login/" + response.data.value.provider, pdata)
					.then(async (response) => {
						// `response` data base on your backend config
						if (response.data.status === 444) {
							hash.value = response.data.hash;
							fauth.value = true; // Option show Otp form incase you using 2fa or any addition security apply to your app you can handle all that from here
						} else if (response.data.status === 445) {
							//do something Optional
						} else {
							await this.useLoginFirst(response.data.u);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
		},
		watch: {
			showForm: {
				handler: async function(showForm) {
					this.$parent.showForm = showForm;
				}
			}
		},
	};
</script>

<style lang="scss" scoped>
.text-blue{
	color: #218BF4;
}

.text-primary-custom>label {
	font-weight: 500 !important;
	color: #000B33 !important;
}
.pr-0{
	padding-right: 0px;
}

.pl-0{
	padding-left: 0px;
}

.border-gray-google{
	border-color: #757575 !important;
	border-width: 0.25px !important;
	border-style: solid !important;
}

.fw-500{
	font-weight: 500;
}

.br-24{
	border-radius: 24px;
}

.bg-white{
	background-color: #fff !important;
}

.ml-10{
	margin-left: 10px;
}
</style>