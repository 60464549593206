<template>
	<div>
		<div class="d-none d-md-flex align-items-center text-primary-dark h4 mb-0 px-2 pb-4">
			<b>{{ $route.name }}</b>
		</div>
		<UsersTable :route="route" :stateVariable="stateVariable" />
		<UsersForm :route="route" :stateVariable="stateVariable" />
		<UsersInfo :route="route" :stateVariable="stateVariable" />
		<UsersDelete :route="route" :stateVariable="stateVariable" />
	</div>
</template>

<script>
	import UsersTable from "./UsersTable.vue";
	import UsersForm from "./UsersForm.vue";
	import UsersInfo from "./UsersInfo.vue";
	import UsersDelete from "./UsersDelete.vue";

	export default {
		components: {
			UsersTable,
			UsersForm,
			UsersInfo,
			UsersDelete
		},
		data() {
			return {
				route: "users",
				stateVariable: "users",
			};
		},
	};
</script>
