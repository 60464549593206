<template>
	<TableDefault
		ref="table"
		:route="'get-languages'"
		:stateVariable="stateVariable"
		:list="languages.list"
		:total="languages.listTotal"
		:filters="filters"
		:type_filter="0"
		:delete="'languages'"
		:showActionsColumn="false"
		:showDeleteButton="false"
		:showTitle="false"
		:addButtonText="'Añadir idioma'"
		:showSelectMultipleColumn="false"
	>
		<template #columns>
			<Column field="name">
                <template #header>
                    <span style="width: 300px !important;">NOMBRE DEL JUEGO</span>
                </template>
                <template #body="slotProps">
                    <span style="width: 300px !important;">{{ slotProps.data.name }}</span>
                </template>
            </Column>
			<Column field="guide">
                <template #header>
                    <span style="width: 400px !important;">CÓDIGO</span>
                </template>
                <template #body="slotProps">
                    <span v-html="slotProps.data.code" />
                </template>
            </Column>
			<Column>
				<template #header>
                    <span style="width: 300px !important;"></span>
                </template>
				<template #body="slotProps">
					<Button class="table-custom-button-action d-flex gap-3" v-if="slotProps.data.code != 'es'" style="width: 300px !important;">
						<img
							v-if="$helper.checkUserHasPermission(route, 'update')"
							src="@/assets/images/icons/edit.svg"
							@click="showRegister(slotProps.data, 'edit')"
						/>

						<img
							v-if="$helper.checkUserHasPermission(route, 'delete')"
							src="@/assets/images/icons/delete.svg"
							@click="deleteRegister(slotProps.data.id, slotProps.data.active)"
						/>
					</Button>
				</template>
			</Column>
		</template>
	</TableDefault>
</template>

<script>
	import Column from "primevue/column";
	import { mapState, mapActions, mapMutations } from "vuex";

	export default {
		components: {
			Column,
		},
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data() {
			return {
				filters: [
					{
						name: "buscar",
						value: null,
						type: "string",
						placeholder: "Buscar..."
                    },
				],
			};
		},
		methods: {
			...mapActions(["deleteRegisters", "getList", "publishGame"]),
			...mapMutations([
				"toggleFormDialog",
				"changeFormDialogMode",
				"changeCurrentRegister",
			]),
			showRegister(e, type) {
				const classList = e.originalEvent?.target.classList;

				if (
					(classList == undefined || !classList?.contains("download-link")) &&
					!classList?.contains("p-checkbox-icon")
				) {
					const register = e.data != undefined ? e.data : e;

					this.changeCurrentRegister({
						stateVariable: this.stateVariable,
						register,
					});

					this.changeFormDialogMode({
						stateVariable: this.stateVariable,
						dialogMode: type,
					});

					if(this.stateVariable == 'languages'){
						if(type == 'show'){
							this.toggleInfoFaceToFaceDialog({
								stateVariable: this.stateVariable,
								show: true,
							});

						}else{
							this.toggleFormDialog({
								stateVariable: this.stateVariable,
								show: true,
							});
						}
						
					}else{
						this.toggleFormDialog({
							stateVariable: this.stateVariable,
							show: true,
						});
					}

					this.$emit("showRegister", register, type);
				}
			},
			deleteRegister(id) {
				this.$confirm.require({
					message: "¿Desea borrar el registro?",
					header: "Borrar registro",
					icon: "pi pi-exclamation-triangle",
					accept: () => {
						this.deleteRegisters({
							url: "/delete-language/" + id
						}).then(() => {
							this.$refs.table.getList();
						});
					},
				});
			},
		},
		computed: {
			...mapState(["languages"]),
		},
	};
</script>

<style lang="scss" scoped>
	.p-avatar.not-active, .rol.not-active {
		background-color: #ABAFB3 !important;
		color: white !important;
		border-width: 0px;
	}

	.f-12{
		font-size: 12px;
	}

	.f-14{
		font-size: 14px;
	}

	.br-8{
		border-radius: 8px !important;
	}

	.gray-color{
		color: #B5B5C3; 
	}

	.fw-500{
		font-weight: 500;
	}

	.border-1{
		border-width: 1px;
		border-style: solid;
	}

	.not-active {
		filter: grayscale(100%);
	}
</style>
