<template>
	<Dialog
		v-model:visible="$store.state.face_to_face_games.dialog"
		:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
		:style="{ width: '40vw' }"
		:modal="dialogDefaults.modal"
		:closeOnEscape="dialogDefaults.closeOnEscape"
		:dismissableMask="dialogDefaults.dismissableMask"
		@hide="hide"
		@show="show"
	>
		<template #header>
			<FormTitle :title="title" />
		</template>

		<form @keydown="$event.key === 'Enter' ? save() : null">
			
			<div class="row gy-3 pt-1">
				<div class="col-12">
					<InputText
						ref="name"
						label="Nombre del juego *"
						:disabled="disabled"
						:error="form.errors.get('name')"
						class="bg-inputs"
						:placeholder="'Introducir nombre'"
						@change-value="(value) => (form.name = value)"
					/>
				</div>

				<div class="col-12 d-block">
					<label for="input" class="text-primary" style="margin-bottom: 16px;">Guía *</label>
					<Editor :placeholder="'Introducir una descripción'" v-model="guide" ref="guide" :disabled="disabled" editorStyle="height: 320px" />
					<div v-if="form.errors.get('guide')" class="text-danger">El campo guía es obligatorio.</div>
				</div>
				<div class="col-12 d-block">
					<label for="input" class="text-primary" style="margin-bottom: 16px;">Objeto *</label>
					<div>
						<MultiSelect :error="form.errors.get('name')" v-model="array_objects_selected" :options="array_objects" optionLabel="name" placeholder="Selecciona un objeto" class="w-100 br-25 bg-inputs-div" :maxSelectedLabels="3"  />
						<div v-if="form.errors.get('array_objects')" class="text-danger">Debes añadir al menos un objeto.</div>
					</div>
				</div>
				<div class="col-12 d-block">
					<label for="input" class="text-primary" style="margin-bottom: 16px;">Orden *</label>
					<div>
						<Dropdown v-model="order" :options="orders" optionLabel="label" optionValue="value" inputStyle="color: #6c757d;font-weight 400 !important; padding-left: 15px;" placeholder="Selecciona un orden" class="w-100 br-25 bg-inputs-div" />
						<div v-if="form.errors.get('order')" class="text-danger">Debes añadir al menos un orden.</div>
					</div>
				</div>
				<div class="col-12 d-block">
					<label for="input" class="text-primary" style="margin-bottom: 16px;">Discapacidades *</label>
					<div>
						<MultiSelect :error="form.errors.get('name')" v-model="array_disabilities_selected" :options="array_disabilities" optionLabel="name" placeholder="Selecciona una discapacidad" class="w-100 br-25 bg-inputs-div" :maxSelectedLabels="3"  />
						<div v-if="form.errors.get('array_disabilities')" class="text-danger">Debes añadir al menos un objeto.</div>
					</div>
				</div>
			</div>
		</form>

		<template #footer v-if="!disabled">
			<div class="col-12 d-flex justify-content-between">
				<Button
					@click="
						toggleFormDialog({
							stateVariable: stateVariable,
							value: false,
						})
					"
					label="Cancelar"
					:disabled="form.busy"
					class="bg-white border btn-secundary"
				/>
				<Button @click="save()" class="btn-primary" :label="label_button" :disabled="form.busy" />
			</div>
		</template>
	</Dialog>
</template>

<script>
	import Form from "vform";
	import Dropdown from "primevue/dropdown";
	import MultiSelect from 'primevue/multiselect';
	import Editor from 'primevue/editor';

	import { mapActions, mapMutations, mapState } from "vuex";

	export default {
		components: {
			Dropdown,
			MultiSelect,
			Editor
		},
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data: () => ({
			form: new Form(),
			modelName: "juego",
			title: `Añadir juego`,
			disabled: false,
			lastPageChange: null,
			checked: 1,
			label_button: "Añadir",
			array_objects: [],
			array_disabilities: [
				{ id: 1, name: 'Persona con discapacidad visual invidente.' },
				{ id: 2, name: 'Persona con discapacidad visual daltónico.' },
				{ id: 3, name: 'Persona con discapacidad auditiva.' },
				{ id: 4, name: 'Persona con discapacidad motórica Extremidades superior' },
				{ id: 5, name: 'Persona con discapacidad motórica Extremidades inferiores' },
				{ id: 6, name: 'Persona con discapacidad intelectual' },
			],
			guide: null,
			guide_text: '',
			array_objects_selected: [],
			array_disabilities_selected: [],
			game_id: 0,
			order: 1,
			orders: [
				{ label: 'Ascendente', value: 1 },
				{ label: 'Descendente', value: 2 },
			]
		}),
		methods: {
			...mapActions(["sendForm", "getObjects", "getRegisters"]),
			...mapMutations(["toggleFormDialog", "changeCurrentRegister"]),
			save() {
				const update = this.face_to_face_games.register != null;
				var url = "/create-game";
				if(update){
					url = "/update-game";
					this.form.game_id = this.game_id;
				}
				this.form.array_objects = this.array_objects_selected;
				this.form.guide = this.guide;
				this.form.guide_text = this.guide_text;
				this.form.order = this.order;
				this.form.array_disabilities = this.array_disabilities_selected;

				this.sendForm({
					method: update ? "put" : "post",
					form: this.form,
					url: url,
					errors: this.form.errors,
				}).then((response) => {
					if (response.status === 200) {
						this.toggleFormDialog({
							stateVariable: this.stateVariable,
							value: false,
						});

						this.getRegisters({
							route: 'get-games',
							stateVariable: this.stateVariable,
							page: this.face_to_face_games.currentPage,
							rows: this.face_to_face_games.rows,
						});
					}
				});
			},
			hide() {
				this.title = `Añadir ${this.modelName}`;
				this.label_button = 'Añadir';
				this.disabled = false;
				this.checked = 1;
				this.face_to_face_games.dialogMode = 'add';

				this.changeCurrentRegister({
					stateVariable: this.stateVariable,
					register: null,
				});
			},
			clearForm() {
				this.form.clear();
				this.form.reset();

				for (const key in this.$refs) {
					if (Object.hasOwnProperty.call(this.$refs, key)) {
						this.$refs[key].model = null;
					}
				}
				this.guide = "";
				this.guide_aux = "";
				this.array_objects = [];
				this.array_disablities = [];
				this.array_objects_selected = [];
				this.array_disabilities_selected = [];
				this.order = 1;
			
			},
			show() {
				this.clearForm();

				//Cargamos en el select los objetos
				this.getObjects();
				this.array_objects = this.face_to_face_objects.list;

				const register = this.face_to_face_games.register;

				if (register != null && this.face_to_face_games.dialogMode == "edit") {
					for (const key in register) {
						if (
							Object.hasOwnProperty.call(register, key) &&
							this.$refs[key] != undefined
						) {
							this.$refs[key].model = register[key];
						}
					}
					this.game_id = register.id;
					this.guide = register.guide;
					this.guide_text = register.guide_text;
					//this.array_objects_selected = register.array_objects;
					
					for (const key in this.array_disabilities) {
						for (const key2 in register.array_disabilities) {
							if(this.array_disabilities[key].id == register.array_disabilities[key2].disability){
								this.array_disabilities_selected.push(this.array_disabilities[key]);
							}
						}
					}

					console.log('hola');
					for (const key in this.array_objects) {
						for (const key2 in register.array_objects) {
							if(this.array_objects[key].id == register.array_objects[key2].id){
								this.array_objects_selected.push(this.array_objects[key]);
							}
						}
					}
					this.order = register.order;

					if (this.face_to_face_games.dialogMode == "edit") {
						this.title = `Modificar ${this.modelName}`;
						this.label_button = 'Guardar';
						this.disabled = false;
					} 
				}
			},
			firstTwoLetters(name) {
				if(name !== undefined){
					return name.substring(0, 2);
				}
			},
		},
		computed: {
			...mapState(["dialogDefaults", "face_to_face_objects", "face_to_face_games"]),
		},
		watch: {
			guide: {
				handler(value) {
					var regex = /(&nbsp;|<([^>]+)>)/ig
					var result = value.replace(regex, "");
					this.guide_text = result;
				}
			}
		}
	}
</script>
<style lang="scss" scoped>
	.ml-2{
		margin-left: 0.5rem;
	}

	.mr-4{
		margin-right: 1rem;
	}

	.ml-4{
		margin-left: 1rem;
	}

	.pyc-17{
		padding-top: 17px;
		padding-bottom: 17px;
	}

	.br-29{
		border-radius: 29px;
	}

	.br-29.selected{
		border-width: 1px;
		border-color: $primary;
		border-style: solid;
		color: #464E5F !important;
	}

	.br-50{
		border-radius: 50%;
	}
	.span.p-dropdown-label.p-inputtext.p-placeholder{
		padding-left: 40px !important;
	}

	div.p-dropdown-trigger>span.p-dropdown{
		color: #6c757d !important;
	}
	.p-dropdown.p-dropdown-label{
		color: #6c757d !important;
	}
</style>