<template>
	<TableDefault
		ref="table"
		:route="route"
		:stateVariable="stateVariable"
		:list="purchase_made.list"
		:total="purchase_made.listTotal"
		:filters="filters"
		:type_filter="0"
		:delete="'purchase_made'"
		:showActionsColumn="false"
		:showDeleteButton="false"
		:showAddButton="false"
		:showSelectMultipleColumn="false"
		:showTitle="false"
		:addButtonText="''"
	>
		<template #columns>
			<Column header="NOMBRE">
				<template #body="slotProps">
					<div class="d-flex align-items-center gap-2">
						<Avatar
							class="bg-light-primary text-white"
							:label="firstTwoLetters(slotProps.data.user.name)"
						/>

						{{ slotProps.data.user.name }}
					</div>
				</template>
			</Column>
			<Column header="COMPRA REALIZADA">
				<template #body="slotProps">
					<div class="d-flex">
						<div class="quantity px-3 py-2 border-radius text-center">
							{{ slotProps.data.quantity }} pistas
						</div>
					</div>
				</template>
			</Column>
			<Column header="TOTAL">
				<template #body="slotProps">
					{{ formatPrice(slotProps.data.price) }}
				</template>
			</Column>
			<Column header="FECHA">
				<template #body="slotProps">
					{{ $helper.formatDate(slotProps.data.created_at, "DD/MM/YYYY") }}
				</template>
			</Column>
		</template>
	</TableDefault>
</template>

<script>
	import Avatar from "primevue/avatar";
	import Column from "primevue/column";
	import { mapState, mapActions, mapMutations } from "vuex";

	export default {
		components: {
			Column,
			Avatar,
		},
		props: {
			route: {
				type: String,
				required: true,
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		data() {
			return {
				filters: [
					{
						name: "buscar",
						value: null,
						type: "string",
						placeholder: "Buscar...",
					},
					{
						name: "from",
						value: null,
						type: "date",
						placeholder: "Desde",
					},
					{
						name: "to",
						value: null,
						type: "date",
						placeholder: "Hasta",
					},
                    {
						name: "button_filter",
						value: 0
					}
				],
			};
		},
		methods: {
			...mapActions(["deleteRegisters", "getList"]),
			...mapMutations([
				"toggleFormDialog",
				"changeFormDialogMode",
				"changeCurrentRegister",
				"toggleInfoDialog",
			]),
			firstTwoLetters(name) {
				return name.substring(0, 2);
			},
			formatPrice(price) {
				return price.toFixed(2) + " EUR";
			},
		},
		computed: {
			...mapState(["purchase_made"]),
		},
	};
</script>

<style lang="scss" scoped>
	.quantity {
		background-color: $light-info;
		color: $info;
	}
</style>
