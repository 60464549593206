<template>
	<div>
		<div class="d-none d-md-flex align-items-center text-primary-dark h4 mb-0 px-2 pb-4">
			<b>{{ $route.name }}</b>
		</div>
        <GameTablesTable :route="route" :stateVariable="stateVariable" />

	</div>
</template>

<script>
	import GameTablesTable from "./GameTablesTable.vue";

	export default {
		components: {
            GameTablesTable
		},
		data() {
			return {
				route: "game_tables",
				stateVariable: "game_tables",
			};
		},
	};
</script>
