<template>
	<Button
		class="custom-padding d-flex align-items-center gap-3 p-3 bg-transparent text-primary"
		:class="[isMobile ? ' justify-content-center' : 'bg-transparent']"
		@click="logout()"
	>
		<img class="ml-auto" src="@/assets/images/icons/logout.svg" />
		<span class="p-button-label mr-auto" style="flex: unset">Cerrar sesión</span>
	</Button>
</template>

<script>
	import { mapActions } from "vuex";
	export default {
		props: {
			isMobile: {
				type: Boolean,
				default: false,
			},
		},
		methods: {
			...mapActions(["logout"]),
		},
	};
</script>

<style lang="scss" scoped>
	.custom-padding {
		padding: 0.75rem 1.25rem !important;
	}

	.bg-transparent {
		transition: $transition;

		&:hover {
			background-color: white !important;
			color: $primary !important;
		}
	}
	.p-button-label{
		font-weight: 400 !important;
	}
	.ml-auto{
		margin-left: auto;
	}
	.mr-auto{
		margin-right: auto;
	}
</style>
