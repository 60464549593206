<template>
	<div
		class="bottom-bar d-flex justify-content-between px-4 bg-white h-100 w-100"
	>
		<div class="d-flex gap-2 align-items-center">
			<span class="text-dark-grey">{{  new Date().toLocaleString('es-ES', {year: 'numeric'}) }} &copy;</span>
			<span><b>Wekha</b></span>
		</div>
		<div class="d-flex gap-4 align-items-center text-dark-grey">
			<span>Acerca de</span>
			<span>Soporte</span>
		</div>
	</div>
</template>

<script>
	export default {};
</script>

<style lang="scss">
	.bottom-bar {
		border-top: 1px solid $light-grey;
	}
</style>
