<template>
	<div>
		<div class="d-flex">
			<label for="input" class="text-primary mt-2">{{ label }}</label>
			<p v-if="forgot_password" @click="$parent.showForm = 'forgot'" class="text-blue cursor-pointer mt-2 mb-1 ml-auto">
				¿Contraseña perdida?
			</p>
		</div>
		
		<Password
			v-model="model"
			:disabled="disabled"
			:placeholder="placeholder"
			:toggleMask="toggleMask"
			:feedback="feedback"
			class="w-100 my-3"
			inputClass="bg-inputs-pass"
			inputStyle="border-radius: 25px"
			:class="[error != null ? 'p-invalid' : '']"
		/>

		<div v-if="error != null" class="text-danger">
			{{ error }}
		</div>
	</div>
</template>

<script>
	import Password from "primevue/password";

	export default {

		components: {
			Password,
		},
		props: {
			label: {
				type: String,
				required: true,
			},
			placeholder: {
				type: String,
			},
			error: {
				type: String,
				default: null,
			},
			disabled: {
				type: Boolean,
				default: false,
			},
			toggleMask: {
				type: Boolean,
				default: true,
			},
			feedback: {
				type: Boolean,
				default: true,
			},
			forgot_password:{
				type: Boolean
			}
		},
		data: () => ({
			model: null,
			showForm: '',
			showForgotPassword: false,
		}),
		watch: {
			model(newValue) {
				this.$emit("change-value", newValue);
			},
		},
	};
</script>

<style lang="scss" scoped>
	:deep(.p-inputtext) {
		width: 100%;
	}

	:deep(.pi.pi-eye) {
		cursor: pointer;
	}

	:deep(.pi.pi-eye-slash) {
		cursor: pointer;
	}

	.text-primary {
		color: #464E5F !important;
	}

	.radius-25{
		border-radius: 25px !important;
	}

	.text-blue{
		color: #218BF4;
	}

	.ml-auto{
		margin-left: auto;
	}

	.p-inputtext:enabled:hover {
		border-color: transparent !important;
	}
</style>
