<template>
	<form id="filters" :class="{'row': type_filter == 0}" class="align-items-center gap-3 pb-1">
		<template v-if="type_filter == 0">
			<div v-for="(filter, index) in filters" :key="index" class="filter col-3">
				<template v-if="filter.name != 'button_filter'">
					<div v-if="filter.type == 'string'" v-tooltip.top="filter.placeholder">
						<span v-if="filter.name == 'buscar'" class="p-input-icon-left search w-100 bg-inputs" >
							<InputText
								:name="filter.name"
								v-model="filter.value"
								:placeholder="filter.placeholder"
								class="br-25 col-3 w-100"
								@input="test()"
							/>
						</span>
						<span v-else-if="filter.name == 'email'" class="p-input-icon-left email bg-inputs w-100">
							<InputText
								:name="filter.name"
								v-model="filter.value"
								:placeholder="filter.placeholder"
								class="br-25 col-3"
							/>
						</span>
						<span v-else class="p-input-icon-left bg-inputs w-100">
							<InputText
								:name="filter.name"
								v-model="filter.value"
								:placeholder="filter.placeholder"
								class="br-25 col-3"
							/>
						</span>
					</div>
					<div v-if="filter.type == 'dropdown'" v-tooltip.top="filter.placeholder">
						<Dropdown
							v-model="filter.value"
							:options="filter.options"
							optionLabel="label"
							optionValue="value"
							:placeholder="filter.placeholder"
							:showClear="true"
							class="br-25 bg-inputs col-3 w-100"
						/>
						<input hidden type="text" :name="filter.name" :value="filter.value" />
					</div>
					<div class="p-input-icon-left calendar w-100" v-if="filter.type == 'date'" v-tooltip.top="filter.placeholder">
						<Calendar
							:name="filter.name"
							v-model="filter.value"
							dateFormat="dd/mm/yy"
							:placeholder="filter.placeholder"
							:manualInput="false"
							showButtonBar
							class="w-100 bg-inputs-div bg-inputs br-25"
							styles="border-radius: 25px;"
							inputClass="w-100"
							inputStyle="border-radius: 25px; padding-left: 0px;"
						/>
						<input hidden type="text" :name="filter.name" :value="filter.value" />
					</div>
				</template>
			</div>
		</template>
		<template v-else>
			<div class="col-12 row">
				<span class="p-input-icon-left search col-6 bg-inputs" >
					<template v-for="(filter, index) in filters" :key="index" >
						<InputText
							v-if="filter.name == 'buscar'"
							v-model="filter.value"
							:name="filter.name"
							:placeholder="filter.placeholder"
							class="br-25 w-100"
						/>

					</template>
				</span>
				<div class="action-buttons d-flex gap-3 align-items-center justify-content-md-end pb-1 f-right col-6 add">
					<Button
						:label="addButtonText"
						class="btn-primary text-white"
						@click="addRegister()"
					>
					<img style="margin-right: 10px;" v-if="addButtonText == 'Añadir usuario'" src="@/assets/images/icons/add_user.svg" width="20" />
					{{ addButtonText }}
					</Button>
					
					<slot name="custom-actions"></slot>
				</div>
			</div>
			<div class="col-12 mt-4 d-flex">
				<span class="p-input-icon-left email col-2 mr-custom-35 bg-inputs">
					<template v-for="(filter, index) in filters" :key="index" >
						<InputText
							v-if="filter.name == 'email'"
							v-model="filter.value"
							:name="filter.name"
							:placeholder="filter.placeholder"
							class="br-25 w-100"
						/>
					</template>
				</span>
				<div class="p-input-icon-left col-2 mr-custom-35">
					<template v-for="(filter, index) in filters" :key="index" >
						<Dropdown
							v-if="filter.name == 'role'"
							v-model="filter.value"
							optionLabel="label"
							optionValue="value"
							:showClear="true"
							:name="filter.name"
							:placeholder="filter.placeholder"
							:options="filter.options"
							class="br-25 bg-inputs bg-inputs col-3 w-100 rol"						
						/>
					
					</template>
				</div>
				<div class="p-input-icon-left col-2 calendar mr-custom-35 br-25 bg-inputs bg-inputs-div">
					<template v-for="(filter, index) in filters" :key="index" >
						<Calendar
							v-if="filter.name == 'from'"
							v-model="filter.value"
							dateFormat="dd/mm/yy"
							:manualInput="false"
							class="w-100"
							inputClass="w-100 bg-inputs bg-inputs-div"
							inputStyle="border-radius: 25px; padding-left: 0px;"
							:name="filter.name"
							:placeholder="filter.placeholder"
							showButtonBar
						/>
					</template>
				</div>
				<div class="p-input-icon-left col-2 calendar mr-custom-35 br-25 bg-inputs bg-inputs-div">
					<template v-for="(filter, index) in filters" :key="index" >
						<Calendar
							v-if="filter.name == 'to'"
							v-model="filter.value"
							dateFormat="dd/mm/yy"
							:manualInput="false"
							class="w-100"
							inputClass="w-100 bg-inputs bg-inputs-div"
							inputStyle="border-radius: 25px; padding-left: 0px;"
							:name="filter.name"
							:placeholder="filter.placeholder"
							showButtonBar
						/>
					</template>
				</div>
				<div class="action-buttons d-flex gap-3 align-items-center justify-content-md-end pb-1 f-right col-3 add">
					<template v-for="(filter, index) in filters" :key="index" >
						<Button
							v-if="filter.name == 'button_filter'"
							:label="'Filtrar'"
							class="btn-primary text-white mr-custom-12"
							@click="filter.value = 1"
						/>					
					</template>
					<slot name="custom-actions"></slot>
				</div>
			</div>
		</template>
	</form>
</template>

<script>
	import InputText from "primevue/inputtext";
	import Calendar from "primevue/calendar";
	import Dropdown from "primevue/dropdown";

	import { mapMutations } from "vuex";

	export default {
		components: {
			InputText,
			Calendar,
			Dropdown,
		},
		props: {
			filters: {
				type: Array,
				default: () => [],
			},
			type_filter: {
				type: Number,
				default: 0,
			},
			addButtonText: {
				type: String,
				default: ''
			},
			stateVariable: {
				type: String,
				required: true,
			},
		},
		methods: {
			...mapMutations([
				"toggleFormDialog",
			]),
			addRegister() {
				this.toggleFormDialog({
					stateVariable: this.stateVariable,
					show: true,
				});

				this.$emit("addRegister");
			},
			test(){
				this.filters.forEach((v) => {
					if(v.name == 'buscar'){
						v.value = 2;
					}
				});
			}
		},
	};
</script>

<style lang="scss" scoped>
	$width: 10rem;

	/*#filters {
		overflow-x: auto;

		.filter {
			width: $width;
		}
	}*/

	.p-input-icon-left > i:first-of-type {
		z-index: 50;
	}

	:deep(.p-calendar .p-inputtext) {
		width: $width;
	}

	:deep(.p-dropdown) {
		width: $width;
	}

	:deep(input) {
		width: $width;
	}

	span {
		position: relative;
	}

	span.email:before {
		content: "";
		position: absolute;
		left: 12px;
		top: 0;
		bottom: 0;
		width: 22px;
		background: url("@/assets/images/icons/email.svg") center / contain no-repeat;
	}

	span.search:before {
		content: "";
		position: absolute;
		right: 35px;
		top: 0;
		bottom: 0;
		width: 22px;
		background: url("@/assets/images/icons/search_icon.svg") center / contain no-repeat;
	}

	span.search{
		input{
			padding-left: 15px !important;
		}
	}

	div.rol:before {
		content: "";
		position: absolute;
		left: 12px;
		top: 0;
		bottom: 0;
		width: 22px;
		background: url("@/assets/images/icons/role_icon.svg") center / contain no-repeat;
	}

	.div.rol>span, div.calendar>span{
		padding-left: 50px !important;
	}

	div.calendar:before {
		content: "";
		position: absolute;
		left: 12px;
		top: 0;
		bottom: 0;
		width: 22px;
		z-index: 9999;
		background: url("@/assets/images/icons/calendar_icon.svg") center / contain no-repeat;
	}

	div.calendar:after {
		content: "";
		position: absolute;
		right: 20px;
		top: 0;
		bottom: 0;
		width: 22px;
		background: url("@/assets/images/icons/arrow_down_blue.svg") center / contain no-repeat;
	}

	div.calendar>span{
		vertical-align: text-top;
	}

	.mr-custom-35{
		margin-right: 35px;
	}
	
	.mr-custom-12{
		margin-right: 12px;
	}
</style>
